<template>
  <div>
    <PageTitle
      title="График мероприятий, проводимых при осуществлении органами государственной власти субъектов РФ внешнеэкономических связей"
    ></PageTitle>
    <div class="container-page big">
      <FilterBlock :regionRfId="regionRfId" :isSubjectRfRole="isSubjectRfRole" @searchFilter="searchFilter" />
      <div class="event-calendar">
        <div v-if="loadingCalendar" class="event-calendar__loader">
          <Loader></Loader>
        </div>
        <Calendar
          v-else
          :columns="getColumnCalendar"
          :step="1"
          class="event-calendar__redes-calendar"
          :attributes="attributes"
          @load-calendar="loadCalendar"
          @edit-card="openCardPage"
        >
          <template v-slot:legend>
            <EventsLegend></EventsLegend>
          </template>

          <template v-slot:customPopover="scope">
            <ul class="events-groups">
              <li v-for="(groupData, groupId) in getEventsGroups(scope)" :key="groupId">
                <EventsGroup :statusId="groupId" :list="groupData" />
              </li>
            </ul>
          </template>
        </Calendar>
      </div>
      <TableBlock
        :listOfEvents="getListOfEvents"
        :loadMoreEvents="loadMoreEvents"
        @changePage="changePageTable"
        @downloadExcel="downloadExcel"
      />
    </div>
    <div v-if="false">{{ eventDataCalendar }}</div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';

  import Calendar from '@/common/components/redesigned/Calendar';
  import PageTitle from '@/common/components/redesigned/PageTitle.vue';
  import Constants from '@/common/constants';
  import resizeEventListener from '@/common/mixins/resizeEventListener';
  import Utils from '@/common/utils';

  import Loader from '@/components/Loader';

  import EventsGroup from '../components/events/EventsGroup.vue';
  import EventsLegend from '../components/events/EventsLegend.vue';
  import FilterBlock from '../components/events/FilterBlock.vue';
  import TableBlock from '../components/events/TableBlock.vue';
  import { EVENTS_VES_ACTIONS_NAME, EVENTS_VES_GETTERS_NAME, EVENTS_VES_MUTATIONS_NAME } from '../store/events/name';
  import { EVENTS_LEGEND_COLORS, EVENTS_LEGEND_ID } from '../utils/constants.js';

  import store from '@/store';
  import Link from '@/utils/links';

  export default {
    name: 'EventsVES',
    mixins: [resizeEventListener],
    components: {
      Calendar,
      PageTitle,
      FilterBlock,
      TableBlock,
      Loader,
      EventsLegend,
      EventsGroup,
    },
    data() {
      return {
        request: {
          pageSize: 10,
          pageNumber: 1,
        },
        filter: {
          regionRfId: this.$route?.query?.regionId ?? null,
        },
        loadMoreEvents: false,
        eventListForCalendar: [],
        loadingCalendar: false,
        windowWidth: window.innerWidth,
        regionRfId: this.$route?.query?.regionId ?? null,
        isSubjectRfRole: false,
      };
    },

    async created() {
      this.checkUserRole();
      this.loadCalendar();
      this.loadPage();
    },

    computed: {
      ...mapGetters({
        getListOfEvents: EVENTS_VES_GETTERS_NAME.getListOfEvents,
      }),

      getColumnCalendar() {
        if (this.windowWidth >= 1280) return 3;
        if (this.windowWidth > 900) return 2;
        return 1;
      },

      getFilterData() {
        const dataForm = { ...this.filter };
        if (dataForm.IsIncreasingOrder) dataForm.IsIncreasingOrder = dataForm.IsIncreasingOrder === 2;
        return dataForm;
      },

      eventsByDate() {
        return (this.eventListForCalendar ?? []).reduce((acc, event) => {
          const startEventDate = this.getStableDateKey(event.startEventDate);
          acc[startEventDate] = acc[startEventDate] ?? [];
          acc[startEventDate].push(event);
          return acc;
        }, {});
      },

      attributes() {
        const attrs = (this.eventListForCalendar ?? []).reduce((acc, event) => {
          const { ferLegendId, startEventDate } = event;
          const legendId = ferLegendId ?? EVENTS_LEGEND_ID.other;

          acc[legendId] = acc[legendId] ?? {};

          const dotColor = EVENTS_LEGEND_COLORS[legendId];
          acc[legendId].dot = acc[legendId].dot ?? {
            style: {
              backgroundColor: dotColor,
            },
          };

          acc[legendId].popover = acc[legendId].popover ?? {
            visibility: 'click',
            isInteractive: true,
            modifiers: [
              {
                name: 'flip',
                enabled: false,
              },
            ],
          };

          acc[legendId].dates = acc[legendId].dates ?? [];
          const startDate = startEventDate;
          if (!acc[legendId].dates.includes(startDate)) {
            acc[legendId].dates.push(startDate);
          }

          return acc;
        }, {});

        return Object.values(attrs);
      },
    },

    methods: {
      ...mapActions({
        searchEvents: EVENTS_VES_ACTIONS_NAME.searchEvents,
      }),

      ...mapMutations({
        setEvents: EVENTS_VES_MUTATIONS_NAME.setEvents,
      }),

      /**
       * @public
       * обрабатывается через миксин `resizeEventListener`
       */
      onResize() {
        this.windowWidth = window.innerWidth;
      },

      checkUserRole() {
        const userRegionRfIds = store._state?.data?.auth?.oidc?.profile?.employee.regionRfIds ?? [];
        const userRoleNames = store._state?.data?.auth?.oidc?.profile?.roleNames;
        this.isSubjectRfRole = userRoleNames === Constants.userRoleNames.subjectRF;

        if (!userRegionRfIds?.includes(Number(this.regionRfId)) && this.isSubjectRfRole) {
          this.regionRfId = userRegionRfIds[0];
          this.$router.replace({ name: 'EventsVES', query: { regionId: userRegionRfIds[0] } });
        }
      },

      async loadPage(more) {
        const res = await this.searchEvents({
          ...this.request,
          ...this.getFilterData,
        });

        if (res.success) {
          this.setEvents({ ...res, more });
        }
        this.loadMoreEvents = false;
      },

      searchFilter(data) {
        this.filter = data;
        this.request.pageNumber = 1;
        this.loadPage();
        this.loadCalendar();
      },

      changePageTable(number, more) {
        if (more) this.loadMoreEvents = true;
        this.request.pageNumber = number;
        this.loadPage(more);
      },

      downloadExcel() {
        const nameFile =
          this.filter?.StartDate || this.filter?.EndDate
            ? `Календарь мероприятий за ${this.filter?.StartDate || ''} - ${this.filter?.EndDate || ''}.xlsx`
            : 'Календарь мероприятий.xlsx';
        Utils.downloadFile(Link.ves.events.downloadExcel, nameFile, this.filter, true);
      },

      async loadCalendar() {
        this.loadingCalendar = true;
        const res = await this.searchEvents(this.getFilterData);
        if (res.success) {
          this.eventListForCalendar = res.data.items;
        }
        this.loadingCalendar = false;
      },

      openCardPage(id) {
        this.$router.push({ name: 'EventsCardVES', params: { id: id } });
      },

      // необходимо получать строковое представление даты без привязки к часовому поясу
      getStableDateKey(date) {
        return new Date(date).toLocaleString();
      },

      getPopoverEvents(scope) {
        const selectedDate = this.getStableDateKey(scope.day.date);
        return this.eventsByDate[selectedDate];
      },

      getEventsGroups(scope) {
        const events = this.getPopoverEvents(scope) ?? [];
        return events.reduce((acc, event) => {
          const { ferLegendId: legendId = null } = event;
          acc[legendId] = acc[legendId] || [];
          acc[legendId].push(event);
          return acc;
        }, {});
      },
    },
  };
</script>

<style lang="scss" scoped>
  .event-calendar {
    &__loader {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 466px;
      margin-bottom: 40px;
    }
  }

  .event-calendar__redes-calendar {
    margin-bottom: 40px;

    @media (max-width: $tablet) {
      padding: 0;
    }

    :deep(.vc-popover-content-wrapper) {
      width: 100%;
    }

    :deep(.v-redes-calendar__wrapper) {
      margin: 0 auto;
    }

    :deep(.vc-dot) {
      display: inline-block !important;

      &.hidden {
        display: none !important;
      }
    }
  }

  .events-groups {
    width: 510px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
</style>
