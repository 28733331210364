<template>
  <div class="table-block-ves-events">
    <div class="table-block-ves-events__download">
      <ButtonComponent @click="downloadExcel" class="button-redesign--download-icon-fill button-redesign--mobile-full">
        <IconComponent name="download-array" />Скачать Excel
      </ButtonComponent>
    </div>

    <div class="table-vertical-line table-block-ves-events__table">
      <table>
        <thead>
          <tr>
            <th></th>
            <th class="column-200">Субъект РФ</th>
            <th class="column-200">Страна-партнер</th>
            <th class="column-200">Дата начала</th>
            <th class="column-200">Место</th>
            <th class="column-200">Наименование</th>
            <th class="column-200">Статус</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(event, index) in listOfEvents.items" :key="listOfEvents.firstItemNumber + index">
            <td>{{ listOfEvents.firstItemNumber + index }}</td>
            <td class="column-200">{{ event.rfRegion }}</td>
            <td class="column-200">{{ event.partnerCountry }}</td>
            <td class="column-200">{{ $momentFormat(event.startEventDate, 'DD MMMM YYYY') }}</td>
            <td class="column-200">{{ event.eventLocation }}</td>
            <td class="column-200">{{ event.name }}</td>
            <td class="column-200">{{ event.eventStatus }}</td>
          </tr>
          <tr v-if="!listOfEvents.items.length">
            <td class="text-center" colspan="7">Нет мероприятий.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <PaginationRedesigned
      v-if="listOfEvents.pagesTotal > 1"
      :page-count="listOfEvents.pagesTotal"
      :current-page="listOfEvents.pageNumber"
      :loadingMore="loadMoreEvents"
      @change="onPageChange"
      @more="onPageMore"
    />
  </div>
</template>

<script>
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import PaginationRedesigned from '@/common/components/redesigned/PaginationRedesigned';
  import IconComponent from '@/common/ui/IconComponent.vue';

  export default {
    name: 'TableBlock',
    emits: ['changePage', 'downloadExcel'],
    components: {
      ButtonComponent,
      PaginationRedesigned,
      IconComponent,
    },
    props: {
      listOfEvents: {
        type: Object,
        default: () => {},
      },
      loadMoreEvents: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      onPageChange(number) {
        this.$emit('changePage', number);
      },
      onPageMore(number) {
        this.$emit('changePage', number, true);
      },
      downloadExcel() {
        this.$emit('downloadExcel');
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/table/table-vertical-line.scss';

  .table-block-ves-events {
    &__download {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 40px;
    }

    &__table {
      margin-bottom: 40px;
      max-height: 80vh;
    }
  }
</style>
